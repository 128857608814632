<template>
  <v-container
    class="pa-0 d-flex"
    style="height: 100%; max-width: 1260px;"
    fluid
  >
    <div
      class="pa-2"
      style="width: 100%;"
    >
      <v-row no-gutters>
        <v-col
          class="pa-1"
          style="min-width: 200px; max-width: 520px;"
        >
          <v-select
            v-model="selectedUnitIds"
            dense
            outlined
            item-text="name"
            item-value="id"
            multiple
            hide-details
            :loading="isLoadingUnits"
            :items="units"
            :label="$t('deconve.units')"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.name }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                {{ otherVideoSelectedText }}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
          class="pa-1"
          sm="auto"
          align-self="center"
        >
          <div class="d-flex flex-row">
            <date-filter
              width="100%"
              input-class="mr-2"
              :start-date="startDate"
              :end-date="endDate"
              @change-start-date="changeStartDate"
              @change-end-date="changeEndDate"
            />
            <rectangle-button
              icon="mdi-download"
              outlined
              color="primary"
              :is-loading="isDownloadReport"
              @clicked="exportReports"
            >
              <div class="d-none d-md-flex">
                {{ $t('deconve.exportReport') }}
              </div>
            </rectangle-button>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="pa-0 ma-0"
      >
        <v-col
          cols="12"
          md="2"
          sm="3"
          class="pa-1"
        >
          <total-card
            height="100%"
            :is-loading="isLoadingReports"
            :subtitle="$t('deconve.visitors')"
            :total="totalNumberOfPeople"
          />
        </v-col>
        <v-col
          class="pa-1"
          cols="12"
          md="10"
          sm="9"
        >
          <report-card
            height="320px"
            :title="$t('deconve.visitors')"
            :is-loading="isLoadingReports"
            :series="reportByDayChartSeries"
            :categories="reportByDayChartLabels"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import DateFilter from '@/components/DateFilter.vue';
import RectangleButton from '@/components/RectangleButton.vue';

// TODO: move these components to components/chart folder
import ReportCard from '../PeopleFlow/ReportCard.vue';
import TotalCard from '../PeopleFlow/TotalCard.vue';

export default Vue.extend({
  name: 'RetailAnalytics',
  components: {
    DateFilter,
    RectangleButton,
    ReportCard,
    TotalCard,
  },
  data: () => ({
    startDate: '',
    endDate: '',
    selectedUnitIds: [],
    isDownloadReport: false,
    isLoadingUnits: true,
    isLoadingReports: true,
    reportByDayChartSeries: [],
    reportByDayChartLabels: [],
    totalByVideoChartSeries: [],
    totalByVideoChartLabels: [],
    updateReportTimer: '',
  }
  ),
  computed: {
    ...mapGetters({
      videos: 'retailAnalytics/videos',
      units: 'retailAnalytics/units',
      reportByUnitByDayToChartData: 'retailAnalytics/reportByUnitByDayToChartData',
      totalNumberOfPeople: 'retailAnalytics/totalNumberOfPeople',
      totalNumberOfPeopleByVideo: 'retailAnalytics/totalNumberOfPeopleByVideo',
    }),
    otherVideoSelectedText() {
      const count = this.selectedUnitIds.length - 1;

      return this.$tc('deconve.otherItems', count, { count });
    },
  },
  watch: {
    selectedUnitIds() {
      this.updateReportByDay();
    },
    reportByUnitByDayToChartData() {
      const { labels, data } = this.reportByUnitByDayToChartData;

      this.reportByDayChartLabels = labels;
      this.reportByDayChartSeries = [{ data, name: this.$t('deconve.visitors') }];
    },
    totalNumberOfPeopleByVideo() {
      const { labels, data } = this.totalNumberOfPeopleByVideo;

      this.totalByVideoChartLabels = labels;
      this.totalByVideoChartSeries = [{ data, name: this.$t('deconve.visitors') }];
    },
  },
  created() {
    this.$moment.locale(this.$i18n.locale);
    this.initDateRange();
    this.fetchReports();
    this.setTimerToUpdateReports();
  },
  beforeDestroy() {
    clearInterval(this.updateReportTimer);
  },
  methods: {
    ...mapActions({
      downloadReport: 'retailAnalytics/downloadReport',
      fetchVideoGroups: 'retailAnalytics/fetchVideoGroups',
      fetchVideos: 'retailAnalytics/fetchVideos',
      fetchReportByUnitByDay: 'retailAnalytics/fetchReportByUnitByDay',
    }),
    initDateRange() {
      const oneMonthBefore = this.$moment().subtract(1, 'month');

      this.startDate = oneMonthBefore.startOf('day').toISOString();
      this.endDate = this.$moment().endOf('day').toISOString();
    },
    changeStartDate(newDate) {
      this.startDate = this.$moment(newDate).startOf('day').toISOString();
      this.updateReportByDay();
    },
    changeEndDate(newDate) {
      this.endDate = this.$moment(newDate).endOf('day').toISOString();
      this.updateReportByDay();
    },
    exportReports() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      this.isDownloadReport = true;
      this.downloadReport(params).then(() => {
        this.isDownloadReport = false;
      }).catch(() => {
        this.isDownloadReport = false;
      });
    },
    updateReportByDay() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      if (this.selectedUnitIds.length > 0) {
        params.unitIds = this.selectedUnitIds;
      }

      this.isLoadingReports = true;

      this.fetchReportByUnitByDay(params).then(() => {
        this.isLoadingReports = false;
      }).catch(() => {
        this.isLoadingReports = false;
      });
    },
    fetchReports() {
      this.isLoadingUnits = true;

      Promise.all([this.fetchVideoGroups(), this.fetchVideos()]).then(() => {
        this.isLoadingUnits = false;
        this.updateReportByDay();
      }).catch(() => {
        this.isLoadingUnits = false;
      });
    },
    setTimerToUpdateReports() {
      const fiveMinutes = 5 * 60 * 1000;

      this.updateReportTimer = setInterval(this.fetchReports, fiveMinutes);
    },
  },
});
</script>
